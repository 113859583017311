import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerContentProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerContent = styled.section``

const Banner = styled.div<{ background: string }>`
  padding: 50px 80px;
  border-radius: 10px;

  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  @media (max-width: 1199px) {
    padding: 30px 40px;
  }

  @media (max-width: 991px) {
    padding: 30px;
  }

  @media (max-width: 575px) {
    padding: 15px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  left: -30px;
  max-width: 590px;

  @media (max-width: 1199px) {
    max-width: 460px;
  }

  @media (max-width: 991px) {
    top: -30px;
    position: relative !important;
    left: unset;
    max-width: 400px;
  }

  @media (max-width: 575px) {
    top: -15px;
  }
`

const BannerContent: React.FC<BannerContentProps> = ({ fields }) => (
  <StyledBannerContent>
    <div className="container">
      <Banner
        className="position-relative d-lg-block d-flex justify-content-center flex-wrap"
        background={fields.backgroundcolor || 'grey'}
      >
        <StyledPlaatjie image={fields.image} alt="" />
        <div
          className={`row mt-lg-0 mt-4 justify-content-${
            fields.imagealignment === 'start' ? `end` : `start`
          }`}
        >
          <div className="col-lg-6">
            <ParseContent content={fields.description || ''} />
          </div>
        </div>
      </Banner>
    </div>
  </StyledBannerContent>
)

export default BannerContent
